let origin = "https://future.zhuneng.cn";
//联建单位列表
const getBatUnitListUrl = `/gateway/hc-neighbour/batUnit/getList`;
// 联建单位详情
const getBatUnitDetailURL = `/gateway/hc-neighbour/batUnit/getDetails`;
//新增联建单位
const addBatUnit = `/gateway/hc-neighbour/batUnit/addOrUpdateInfo`;
//删除创业导师
const deleteBatUnitURL = `/gateway/hc-neighbour/batUnit/deleteById`;
//编辑手机号
const batUnitEdit = `/gateway/hc-neighbour/batUnit/batUnitEdit`;
export { getBatUnitListUrl, getBatUnitDetailURL, addBatUnit, deleteBatUnitURL,batUnitEdit };
